interface IBreadcrumb {
  link?: string | null;
  label: string;
}

interface IGenre {
  slug: string;
  name: string;
}

interface ITag {
  slug: string;
  name: string;
}

interface IReview {
  id: number
  slug: string;
  title: string;
  content?: string;
  image_url: string;
  image_path: string;
}

interface IPost {
  id: number
  slug: string;
  title: string;
  author: string;
  content?: string;
  image_url: string;
  image_path: string;
  created_at: string;
  updated_at: string;
}

export enum ColorModes {
   MODE_LIGHT = 'light',
   MODE_DARK = 'dark'
}

export enum OneLinkTargetScreens {
  Reader = 'book_onboarding',
  BookSummary = 'book',
}

export type { IBreadcrumb, IGenre, ITag, IReview, IPost }